var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.computedRelevancy,"loading":_vm.loading,"loader-height":"2","items-per-page":-1,"no-data-text":_vm._f("localize")('noDataAvailable'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.transport_mode",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"silver-chalice"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.TRANSPORT_ICONS[item.transport_mode]))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("localize")(item.transport_mode)))])]),(item['black_list'])?[_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-flex align-center ml-2"},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-account-cancel")]),_c('span',{staticClass:"error--text text-caption"},[_vm._v(" "+_vm._s(item['black_list'].length))])],1)]}}],null,true)},[[_c('div',{staticClass:"py-1"},[_c('h5',{staticClass:"text-subtitle-2 mb-1"},[_vm._v(_vm._s(_vm._f("localize")('blockedClients'))+" ("+_vm._s(item['black_list'].length)+")")]),(item['black_list'].length <= 5)?_c('ul',{staticClass:"px-2"},_vm._l((item['black_list']),function(client,index){return _c('li',{key:index},[_vm._v(_vm._s(client['name'])+" "),_c('i',{staticClass:"text-caption"},[_vm._v("(ID: "+_vm._s(client['id'])+")")])])}),0):_vm._e()])]],2)]:_vm._e()],2)]}},{key:"item.type_of_goods",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},_vm._l((item['type_of_goods']),function(type){return _c('v-chip',{key:type,staticClass:"mr-1 px-1",attrs:{"x-small":"","label":""}},[_vm._v(_vm._s(_vm.goodTypesList.find(function (x) { return x.key === type; }).value))])}),1)]}},{key:"item.delivery_terms",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},_vm._l((item['delivery_terms']),function(value){return _c('v-chip',{key:value,staticClass:"mr-1 px-1",attrs:{"x-small":"","label":""}},[_vm._v(_vm._s(value))])}),1)]}},{key:"item.country_from",fn:function(ref){
var item = ref.item;
return _vm._l((item['country_from']),function(country,index){return _c('span',{key:country.code},[(index < 2)?[(index > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(_vm._s(country.name))]:_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption ml-1"},[_vm._v("+"+_vm._s(item['country_from'].length - 2))]):_vm._e()],2)})}},{key:"item.country_to",fn:function(ref){
var item = ref.item;
return _vm._l((item['country_to']),function(country,index){return _c('span',{key:country.code},[(index < 2)?[(index > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(_vm._s(country.name))]:_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption ml-1"},[_vm._v("+"+_vm._s(item['country_to'].length - 2))]):_vm._e()],2)})}},{key:"item.special_requirements",fn:function(ref){
var item = ref.item;
return _vm._l((item['special_requirements']),function(requirement){return _c('v-chip',{key:requirement,staticClass:"mr-1 px-1",attrs:{"x-small":"","label":""}},[_vm._v(_vm._s(_vm._f("localize")(requirement)))])})}},{key:"item.receiver",fn:function(ref){
var item = ref.item;
return _vm._l((item['receiver']),function(receiverItem){return _c('v-chip',{key:receiverItem,staticClass:"mr-1 px-1",attrs:{"x-small":"","label":""}},[_vm._v(_vm._s(_vm._f("localize")(receiverItem)))])})}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(_vm.deleteQueue.includes(item.id))?_c('v-progress-circular',{attrs:{"indeterminate":"","width":1,"size":20}}):_c('v-menu',{attrs:{"left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"45","tile":""}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editRelevancy(item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('edit')))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.copyRelevancy(item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('copy')))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openDeleteRelevancy(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('delete')))])],1)],1)],1)]}}])}),_c('SimpleDialog',{ref:"deleteRelevancyDialog",attrs:{"title":_vm._f("localize")('deleteRelevancy'),"text":_vm._f("localize")('deleteRelevancyText'),"accept-text":_vm._f("localize")('delete'),"decline-text":_vm._f("localize")('back')},on:{"decline":_vm.cancelDeleteRelevancy,"accept":_vm.deleteRelevancy}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }